<template>
  <div :class="$style.app">
    <div :class="$style.manual">
      <p>
        Переменные указываются в фигурных скобках (например,
        <code>{status}</code>).
      </p>
    </div>
    <form @submit.prevent>
      <h4>Шаблон письма о новом заказе</h4>
      <Textarea
        v-model="templates.newOrder"
        :rows="10"
        :placeholder="defaultTemplate['newOrder']"
      />
      <div :class="$style.action">
        <Button type="primary" @click="submitForm('new-order')">
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон письма о том, что дилер отправил реквизиты на модерацию</h4>
      <Textarea
        v-model="templates.newDealerClientRequesite"
        :rows="10"
        :placeholder="defaultTemplate['newDealerClientRequesite']"
      />
      <div :class="$style.action">
        <Button
          type="primary"
          @click="submitForm('new-dealer-client-requesite')"
        >
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон изменения или модерации резвизитов клиента</h4>
      <Textarea
        v-model="templates.updateClientRequesite"
        :rows="10"
        :placeholder="defaultTemplate['updateClientRequesite']"
      />
      <div :class="$style.action">
        <Button type="primary" @click="submitForm('update-client-requesite')">
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон письма о создании нового заказа менеджером</h4>
      <Textarea
        v-model="templates.createOrder"
        :rows="10"
        :placeholder="defaultTemplate['createOrder']"
      />
      <div :class="$style.action">
        <Button type="primary" @click="submitForm('create-order')">
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон редактирования заказа менеджером</h4>
      <Textarea
        v-model="templates.editOrder"
        :rows="10"
        :placeholder="defaultTemplate['editOrder']"
      />
      <div :class="$style.action">
        <Button type="primary" @click="submitForm('edit-order')">
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон изменения статуса заказа</h4>
      <Textarea
        v-model="templates.changeOrderStatus"
        :rows="10"
        :placeholder="defaultTemplate['changeOrderStatus']"
      />
      <div :class="$style.action">
        <Button type="primary" @click="submitForm('change-order-status')">
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон нового заказа для поставщика</h4>
      <Textarea
        v-model="templates.createOrderForSupplier"
        :rows="10"
        :placeholder="defaultTemplate['createOrderForSupplier']"
      />
      <div :class="$style.action">
        <Button type="primary" @click="submitForm('create-order-for-supplier')">
          Сохранить
        </Button>
      </div>
    </form>
    <form @submit.prevent>
      <h4>Шаблон сообщения о том, что товар появился в наличии</h4>
      <Textarea
        v-model="templates.subscriptionProductIsAvailable"
        :rows="10"
        :placeholder="defaultTemplate['subscriptionProductIsAvailable']"
      />
      <div :class="$style.action">
        <Button
          type="primary"
          @click="submitForm('subscription-product-is-available')"
        >
          Сохранить
        </Button>
      </div>
    </form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import Button from '@/components/atoms/Button'
import Textarea from '@/components/atoms/Textarea'

const NEW_ORDER = `Новый заказ: {orderName}
Ожидаемая дата доставки: {deliveryDate}
Общая цена заказа: {price}
Стоимость доставки: {deliveryPrice}
Тип оплаты: {payment}
Склад: {warehouse}
Тип доставки: {deliveryType}
<Адрес доставки: {deliveryAddress}
Комментарии к доставке: {comment}>
Получатель: {name}
Тел. получателя: {phone}
E-mail: {email}
Список продуктов:
{products}`

const NEW_DEALER_CLIENT_REQUESITE = `Новые клиентские реквизиты: {requesiteName}
Юридический адрес: {legalAddress}
Имя дилера: {name}
E-mail дилера: {email}`

const UPDATE_CLIENT_REQUESITE = `Новые клиентские реквизиты: {requesiteName}
Юридический адрес: {legalAddress}
Ссылка реквизитов в личном кабинете дилера: {clientLink}`

const CREATE_ORDER = `Новый заказ {orderName} был оформлен. Подробная информация по ссылке: {clientLink}`
const EDIT_ORDER = `Ваш заказ {orderName} был изменен. Подробная информация по ссылке: {clientLink}`
const CHANGE_STATUS_ORDER = `Статус вашего заказа  был изменен: {statusMessage}. Подробная информация по ссылке: {clientLink}`

const CREATE_ORDER_FOR_SUPPLIER = `
Новый заказ: {orderName}
Ссылка на заказ в личном кабинете: {orderLink}
Ожидаемая дата доставки: {deliveryDate}
Общая цена заказа: {price}
Форма оплаты: {payment}
Тип доставки: {deliveryType}
<Адрес доставки: {deliveryAddress}
Комментарии к доставке: {comment}>
Получатель: {name}
Тел. получателя: {phone}
E-mail: {email}
Список продуктов:
{products}`

const SUBSCRIPTION_PRODUCT_IS_AVAILABLE = `Уважаемый {name}! 
Ранее вы подписались на товар {product}. Рады сообщить, что товар поступил в продажу.`

export default {
  components: { Button, Textarea },
  data() {
    return {
      templates: {
        newOrder: '',
        createOrder: '',
        editOrder: '',
        changeOrderStatus: '',
        newDealerClientRequesite: '',
        updateClientRequesite: '',
        createOrderForSupplier: '',
        subscriptionProductIsAvailable: ''
      }
    }
  },
  computed: {
    defaultTemplate() {
      return {
        newOrder: NEW_ORDER,
        createOrder: CREATE_ORDER,
        editOrder: EDIT_ORDER,
        changeOrderStatus: CHANGE_STATUS_ORDER,
        newDealerClientRequesite: NEW_DEALER_CLIENT_REQUESITE,
        updateClientRequesite: UPDATE_CLIENT_REQUESITE,
        createOrderForSupplier: CREATE_ORDER_FOR_SUPPLIER,
        subscriptionProductIsAvailable: SUBSCRIPTION_PRODUCT_IS_AVAILABLE
      }
    }
  },
  async created() {
    await this.getTemplates()
  },
  methods: {
    async getTemplates() {
      const {
        value,
        error
      } = await delivery.AddwineCore.MailActions.getOrderTemplatesList()

      if (error) {
        console.error(error)
        return
      }
      value.forEach((item) => {
        if (Object.keys(this.templates).includes(this.toCamelCase(item.Type))) {
          this.templates[this.toCamelCase(item.Type)] = item.Body
        }
      })
    },
    async submitForm(type) {
      const {
        error
      } = await delivery.AddwineCore.MailActions.updateOrCreateOrderTemplateByType(
        type,
        this.templates[this.toCamelCase(type)],
        'sellers'
      )

      if (error) {
        console.error(error)
        alert('Ошибка сохранения шаблона!')
        return
      }

      await this.getTemplates()
      alert('Шаблон успешно сохранен.')
    },
    toCamelCase(string) {
      return string.replace(/-./g, (x) => x[1].toUpperCase())
    }
  }
}
</script>

<style lang="scss" module>
.app {
  width: 100%;
  min-height: 92vh;
  padding: 2rem;
  background: $smoky;
  display: flex;
  flex-direction: column;
  .manual {
    margin-bottom: 3rem;
  }
  .action {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
